import videoLazyLoadStart from "./modules/videos-lazy-load";
import animations from "./modules/animations";

function runCritical() {
	videoLazyLoadStart();
	animations();
}

setTimeout( runCritical, 5000 );

window.addEventListener( "load", () => {
	runCritical();
} );
