export default () => {
	try {
		// Check active section in viewport
		const sections = document.querySelectorAll( ".js-listen-scroll-section" );

		if ( sections.length ) {
			const intersectionObserver = new IntersectionObserver( entries => {
				entries.forEach( entry => {
					if ( entry.intersectionRatio > 0 ) {
						entry.target.classList.add( "show-viewport" );
					}
				} );
			} );

			sections.forEach( section => {
				intersectionObserver.observe( section );
			} );
		}
	} catch ( error ) {
		console.error( error, "error animations" );
	}
};
