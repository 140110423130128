const loadLazyLoadedVideos = () => {
	const lazyVideos = document.querySelectorAll( ".js-video-lazy-load" );

	setTimeout( () => {
		lazyVideos.forEach( video => {
			if ( video.getAttribute( "src" ) ) {return;}

			let videoSrc = "";

			if ( video.getAttribute( "data-custom-video-lazy-src" ) ) {
				videoSrc = video.getAttribute( "data-custom-video-lazy-src" );
			} else {
				const childWithSrc = video.querySelector( "[data-custom-video-lazy-src]" );
				if ( childWithSrc ) {
					videoSrc = childWithSrc.getAttribute( "data-custom-video-lazy-src" );
				} else {
					return;
				}
			}

			video.setAttribute( "src", videoSrc );

			if ( video.hasAttribute( "autoplay" ) ) {
				video.load();
				fetchVideoAndPlay( videoSrc );
			}

			function fetchVideoAndPlay( src ) {
				fetch( src )
					.then( response => response.blob() )
					.then( blob => {
						video.srcObject = blob;
						return video.play();
					} )
					.catch( () => {
						setTimeout( () => {
							fetchVideoAndPlay( src );
						}, 2000 );
					} );
			}
		} );
	}, 4000 );
};

export default loadLazyLoadedVideos;
